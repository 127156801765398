@tailwind base;
@tailwind components;
@tailwind utilities;

@import '@sweetalert2/theme-borderless/borderless.scss';

.swal2-container.swal2-backdrop-show {
    @apply bg-slate-900;
}

body {
    @apply text-white bg-slate-900;
}
